import React, {useState} from 'react';
import InputMask from 'react-input-mask';
import {FaMapMarkerAlt} from 'react-icons/fa';

import Modal from '../Modal';
import mail from '../../services/mail';

import './index.css';

function Footer() {

    const [area, setArea] = useState('');
    const [tipo, setTipo] = useState('');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [estado, setEstado] = useState('');
    const [msg, setMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState(false);

    async function SendMail(e) {
        e.preventDefault();
        try { 

            const data = {
                'area': area,
                'tipo': tipo,
                'nome': nome,
                'email': email,
                'telefone': telefone,
                'estado': estado,
                'mensagem': msg
            }

            await mail.post('/mail.php', data);

            //console.log(response);

            setSuccessMsg(true);

            setArea('');
            setTipo('');
            setNome('');
            setEmail('');
            setTelefone('');
            setEstado('');
            setMsg('');

            setTimeout(function(){ setSuccessMsg(false); }, 6000);


        } catch (error) {
            console.error(error) ;
        }
    }
    
    return (
        <>
            <footer id="orcamento">
                <div className="footer-filter">
                    <div className="container">
                        <h1>Preencha os campos abaixo. <br /> Em breve retornaremos o contato.</h1>
                        <form className="contact-form" encType="multipart/form-data">
                            <div className="medium">
                                <div>
                                    <label htmlFor="area-do-projeto">Área do Projeto</label>
                                    <input type="text" name="area-do-preojeto" onChange={(e) => setArea(e.target.value)} value={area} />
                                </div>
                                <div>
                                    <label htmlFor="tipo-de-grama">Tipo de Grama</label>
                                    <select name="tipo-de-grama" onChange={(e) => setTipo(e.target.value)} value={tipo}>
                                        <option value=""></option>
                                        <option value="Decorativa">Decorativa</option>
                                        <option value="Esportiva">Esportiva</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <input type="text" name="nome" placeholder="Nome*" onChange={(e) => setNome(e.target.value)} value={nome} required/>
                            </div>
                            <div>
                                <input type="text" name="email" placeholder="E-mail*" onChange={(e) => setEmail(e.target.value)} value={email} required/>
                            </div>
                            <div className="medium2">
                                <div>
                                    <InputMask type="tel" mask={ telefone.length > 14 ? '(99) 99999-9999' : '(99) 9999-99999' } maskChar={null} name="telefone" placeholder="Telefone*" onChange={(e) => setTelefone(e.target.value)} value={telefone} required/>
                                </div>
                                <div>
                                    <select name="estado" onChange={e => setEstado(e.target.value)} value={estado}>
                                        <option value="Estado">Estado</option>
                                        <option value="AC">Acre</option>
                                        <option value="AL">Alagoas</option>
                                        <option value="AP">Amapá</option>
                                        <option value="AM">Amazonas</option>
                                        <option value="BA">Bahia</option>
                                        <option value="CE">Ceará</option>
                                        <option value="DF">Distrito Federal</option>
                                        <option value="ES">Espírito Santo</option>
                                        <option value="GO">Goiás</option>
                                        <option value="MA">Maranhão</option>
                                        <option value="MT">Mato Grosso</option>
                                        <option value="MS">Mato Grosso do Sul</option>
                                        <option value="MG">Minas Gerais</option>
                                        <option value="PA">Pará</option>
                                        <option value="PB">Paraíba</option>
                                        <option value="PR">Paraná</option>
                                        <option value="PE">Pernambuco</option>
                                        <option value="PI">Piauí</option>
                                        <option value="RJ">Rio de Janeiro</option>
                                        <option value="RN">Rio Grande do Norte</option>
                                        <option value="RS">Rio Grande do Sul</option>
                                        <option value="RO">Rondônia</option>
                                        <option value="RR">Roraima</option>
                                        <option value="SC">Santa Catarina</option>
                                        <option value="SP">São Paulo</option>
                                        <option value="SE">Sergipe</option>
                                        <option value="TO">Tocantins</option>
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="mensagem">Mensagem*</label>
                                <textarea name="mensagem" onChange={e => setMsg(e.target.value)} value={msg} required></textarea>
                            </div>
                            <button type="submit" onClick={SendMail}>Enviar</button>
                        </form>
                        {successMsg && (<Modal />)}
                    </div>
                    <div id="WhatsAppFixo">
                        <a id="BotaoWhatsApp" title="Enviar Mensagem" href="https://api.whatsapp.com/send?phone=5511969098399" target="_blank" rel="noopener noreferrer">
                        <span id="TextoChamada">Olá, como podemos lhe ajudar?</span>
                            <img src="https://d335luupugsy2.cloudfront.net/cms/files/52484/1527884427/%24wvb6w235tna" alt=" Tirar dúvida pelo WhatsApp" />
                        </a>
                    </div>
                    <div className="footer-info">
                        <p><FaMapMarkerAlt size={13} color="#4EE66C" /> R. Conceição, 160 - Santo Antônio, São Caetano do Sul - SP, 09530-060 | Whatsapp 11 96909-8399</p>
                    </div> 
                </div>
            </footer>
        </>
    );
}

export default Footer;

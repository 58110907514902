import React, { useState } from 'react';
import {FaWhatsapp} from 'react-icons/fa';

import logo from '../../assets/logo.svg';

import './menu.css';
import './index.css';

function Header() {

    const [openMobile, setOpenMobile] = useState(false);
    const [header, setHeader] = useState(false);
    const [currentLogo, setCurrentLogo] = useState(false);

    const OnScrollHeader = () => {
      window.addEventListener('scroll', () => {
        if(window.scrollY >= 300){
          setHeader(true);
          setCurrentLogo(true);
        }else{
          setHeader('');
          setCurrentLogo('');
        }
      });
    }
    
    OnScrollHeader();

    const hideMenuOnClick = () => {
      setOpenMobile(false)
    }

    return (
      <header className={openMobile ? "active" : "inative"}>
        <div className="container">
          <div className="mobile">
            <button type="button" onClick={() => setOpenMobile(!openMobile)} className={openMobile ? "hamburger hamburger--stand is-active" : "hamburger hamburger--spin"}>
              <span className="hamburger-box">
              <span className="hamburger-inner"></span>
              </span>
            </button>
            <div className="logo">
              <a href="#home"><img src={logo} alt="Playball" /></a>
            </div> 
          </div>
          <div className={openMobile ? "header-mobile-active" : "header-mobile-inative"}>
            <ul>
              <li><a href="#home" onClick={() => hideMenuOnClick()}>Home</a></li>
              <li><a href="#playball-grama" onClick={() => hideMenuOnClick()}>Playball grama</a></li>
              <li><a href="#gramas-esportivas" onClick={() => hideMenuOnClick()}>Grama esportiva</a></li>
              <li><a href="#gramas-decorativas" onClick={() => hideMenuOnClick()}>Grama decorativa</a></li>
              <li><a href="#projetos-especiais" onClick={() => hideMenuOnClick()}>Projetos especiais</a></li>
              <li><a href="https://api.whatsapp.com/send?phone=5511969098399" target="_blank" rel="noreferrer" onClick={() => hideMenuOnClick()}><FaWhatsapp size={20} color="#ffffff" className="whatsapp-icon" />Orçamento</a></li>
            </ul>
          </div>
          <div className={header ? "menu scrolled-header" : "menu"}>
            <div className={currentLogo ? "logo scrolled-logo" : "logo" }>
              <a href="#home"><img src={logo} alt="Playball" /></a>
            </div>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#playball-grama">Playball grama</a></li>
              <li><a href="#gramas-esportivas">Grama esportiva</a></li>
              <li><a href="#gramas-decorativas">grama decorativa</a></li>
              <li><a href="#projetos-especiais">Projetos especiais</a></li>
              <li className="last"><a href="https://api.whatsapp.com/send?phone=5511969098399" target="_blank" rel="noreferrer"><FaWhatsapp size={20} color="#ffffff" className="whatsapp-icon" />Orçamento</a></li>
            </ul>
          </div>
          
        </div>
      </header>
    );
  }
  
  export default Header;
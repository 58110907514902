import React from 'react';
import { FaCheck } from 'react-icons/fa';

import './index.css';

function Modal() {
    return (
        <>
            <div className="modal-bg show-modal">
                <div className="modal">
                    <div className="modal-content">
                        <FaCheck size={30} color="#286A2D" className="check-icon" />
                        <div className="center">Mensagem enviada com sucesso!<br />Em breve retornaremos o contato</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Modal;
import React, {useState} from 'react';
import {FaWhatsapp} from 'react-icons/fa';
import {GrClose} from 'react-icons/gr';
import {IoIosMail} from 'react-icons/io';
import Fade from 'react-reveal/Fade';
import { Parallax } from 'react-parallax';

import Header from '../../components/Header';
import Slide from '../../components/Slide';
import Footer from '../../components/Footer';

import play from '../../assets/play.png';
import form1 from '../../assets/form1.png';
import form2 from '../../assets/form2.png';
import form3 from '../../assets/form3.png';
import form4 from '../../assets/form4.png';
import parallax from '../../assets/parallax.jpg';
import project1 from '../../assets/cdc-falconeres.jpg';
import project2 from '../../assets/favela.jpg';
import project3 from '../../assets/cdc-portuguesinha.jpg';
import graminha from '../../assets/graminha.svg';
import gramaEsportiva1 from '../../assets/grama-esportiva1.jpg';
import gramaEsportiva2 from '../../assets/grama-esportiva2.jpg';
import gramaEsportiva3 from '../../assets/grama-esportiva3.jpg';
import gramaEsportiva4 from '../../assets/grama-esportiva4.jpg';
import gramaEsportiva5 from '../../assets/grama-esportiva5.jpg';
import gramaEsportiva6 from '../../assets/grama-esportiva6.jpg';
import decorativaItem from '../../assets/decorativa.png';
import gramaDecorativa1 from '../../assets/decorativa1.jpg';
import gramaDecorativa2 from '../../assets/decorativa2.jpg';
import gramaDecorativa3 from '../../assets/decorativa3.jpg';
import gramaDecorativa4 from '../../assets/decorativa4.jpg';
import gramaDecorativa5 from '../../assets/decorativa5.jpg';
import gramaDecorativa6 from '../../assets/decorativa6.jpg';


import './index.css';


function Home() {

    const [modal, setModal] = useState(false);
    const [footerMenu, setFooterMenu] = useState(true);
    const [videoSRC, setVideoSRc] = useState('https://player.vimeo.com/video/513594617?loop=1');

    const OnScrollFooterMenu = () => {
        //const target = document.querySelector('#orcamento');
        window.addEventListener('scroll', () => {
          if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight){
            setFooterMenu(false);
          }else{
            setFooterMenu(true);
          }
        });
    }

    OnScrollFooterMenu();

    let srcMobile = 'https://player.vimeo.com/video/513594617?loop=1';

    const OnScrollVideo = () => {
        window.addEventListener('scroll', () => {
          if(window.scrollY >= 300){
           setVideoSRc("");
          }else{
            setVideoSRc("https://player.vimeo.com/video/513594617?loop=1");
          }
        });
    }

    OnScrollVideo();

    function handleModal(e){
        setModal(true);
    }

    return (
        <>
            <Header />
            <main>
                {modal && (
                    <>
                        <div className={modal ? "mobile-video-modal show-modal" : "mobile-video-modal"}>
                            <div className="modal">
                                <GrClose size={20} color="#ffffff" className="close-icon" onClick={() => setModal(false)}  />
                                <iframe id="video-mobile"  src={srcMobile} width="100%" frameBorder="0" title="home" allow="autoplay; fullscreen" allowFullScreen=""></iframe>
                            </div>
                        </div>
                    </>
                )}
               
                <section id="home">
                    <div className="video">
                        <iframe id="video-desk" src={videoSRC} width="100%" frameBorder="0" title="home" allow="autoplay; fullscreen" allowFullScreen=""></iframe>
                        <div className="bg-mobile" onClick={(e) => handleModal(e)}>
                            <div className="filter-mobile">
                                <img src={play} alt="Play no Vídeo" className="mlast" />
                            </div>
                        </div>
                    </div>
                    <ul className="footer-menu">
                        <li><a href="#gramas-esportivas">Grama esportiva</a></li>
                        <li><a href="#gramas-decorativas">Grama decorativa</a></li>
                    </ul>
                    {footerMenu && (
                        <>
                            <ul className="footer-menu menu-mobile">
                                <li><a href="https://api.whatsapp.com/send?phone=5511969098399" target="_blank" rel="noreferrer" className="mlast"><FaWhatsapp size={35} color="#17501b" className="whatsapp-icon whats-mobile" />Quero um orçamento</a></li>
                            </ul>
                        </>
                    )}
                    
                </section>
                <section id="playball-grama">
                    <div className="container">
                        <div className="playball-content">
                            <div className="info-text">
                                <h1>Qualidade, tradição e o melhor preço. Playball há 38 anos liderando o mercado de grama sintética no Brasil.</h1>
                                <p>Pioneira no Brasil, em 1994, a Playball trouxe o primeiro campo em grama sintética, dos Estados Unidos para nosso país.</p>
                                <p>Desde então seguimos nos aprimorando na fabricação da grama sintética, em nosso complexo industrial, equipado com máquinas de última geração e com excelente capacidade produtiva.</p>
                                <p>Somos reconhecidos pela seriedade, qualidade e comprometimento com que nos dedicamos aos nossos projetos.</p>
                                <p>Nossos produtos tiveram suas qualidades comprovadas, foram testados pelo IPT - Instituto de Pesquisas Tecnológicas da Cidade Universitária de São Paulo.</p>
                                <p>Produzimos grama sintética de alta performance a um preço que cabe em seu orçamento, além de garantirmos a entrega no prazo acordado.</p>
                            </div>
                            <div className="forms">
                                <Fade left><img src={form1} className="form1" alt="Qualidade Comprovada" /></Fade>
                                <Fade right><img src={form3} className="form3" alt="Experiência + 38 anos" /></Fade>
                                <Fade bottom><img src={form2} className="form2" alt="Inovação e Tecnologia" /></Fade>
                                <Fade top><img src={form4} className="form4" alt="Prazo garantido" /></Fade>
                            </div>
                        </div>
                    </div>
                    <div id="parallax">
                        <Parallax bgImage={parallax} strength={500}>
                            <div className="parallax-content">
                                <div><h2>Do projeto à instalação</h2></div>
                            </div>
                        </Parallax>
                    </div>
                </section>
                <section id="projetos">
                    <div className="container">
                        <h2>Confira alguns projetos</h2>
                    </div>
                    <div className="projetos-content">
                        <div>
                            <img src={project1} alt="Projeto 1" />
                        </div>
                        <div>
                            <img src={project2} alt="Projeto 2" />
                        </div>
                        <div>
                            <img src={project3} alt="Projeto 3" />
                        </div>
                    </div>
                    <section id="gramas-esportivas">
                        <div className="filter">
                            <h1>Grama<br className="title-mobile" /> Esportiva</h1>
                            <h2>Nas quadras, campos e academias</h2>
                            <br className="br_mobile" /><br className="br_mobile" />
                            <p>
                                A grama sintética esportiva é a grande tendência em pisos esportivos.<br className="hide" />
                                São muitos benefícios: <strong>versatilidade, qualidade, alta performance e beleza.</strong><br className="hide" />
                                Outra grande vantagem é o baixo custo de manutenção, pois não precisa ser irrigada.
                            </p>
                        </div>
                    </section>
                </section>
                <section id="slides">
                    <div className="container">
                        <div className="slides-info">
                            <h3>Opções de altura:</h3>
                            <div className="list">
                                <div>
                                    <img src={graminha} alt="Graminha"/>
                                    <span>40mm</span>
                                </div>
                                <div>
                                    <img src={graminha} alt="Graminha"/>
                                    <span>50mm</span>
                                </div>
                                <div>
                                    <img src={graminha} alt="Graminha"/>
                                    <span>60mm</span>
                                </div>
                            </div>
                        </div>
                        <div className="slides-info2">
                            <h4>Qualidade comprovada pelo laboratório de IPT</h4>
                            <p>(Instituto de pesquisas tecnológicas da cidade universitária de Sāo Paulo)</p>
                        </div>
                        <Slide />
                    </div>
                    <div className="grama-esportiva-gallery">
                        <div>
                            <img src={gramaEsportiva1} alt="Grama Esportiva 1"/>
                        </div>
                        <div>
                            <img src={gramaEsportiva2} alt="Grama Esportiva 2"/>
                        </div>
                        <div>
                            <img src={gramaEsportiva3} alt="Grama Esportiva 3"/>
                        </div>
                        <div>
                            <img src={gramaEsportiva4} alt="Grama Esportiva 4"/>
                        </div>
                        <div>
                            <img src={gramaEsportiva5} alt="Grama Esportiva 5"/>
                        </div>
                        <div>
                            <img src={gramaEsportiva6} alt="Grama Esportiva 6"/>
                        </div>
                    </div>
                </section>
                <section id="gramas-decorativas">
                    <div className="gramas-decorativas">
                        <div className="filter">
                            <h1>Grama<br className="title-mobile" /> Decorativa</h1>
                            <h2>Áreas de lazer, ambientes externos e internos</h2>
                            <br className="br_mobile" /><br className="br_mobile" />
                            <p>
                                Uma excelente opção para você que deseja ter uma área verde<br className="hide" /> 
                                descomplicado e de fácil manutenção. A grama sintética decorativa é fácil e<br className="hide" /> 
                                rápida de ser instalada e tem durabilidade maior do que as gramas naturais.<br className="hide" />
                                Pode ser aplicada em jardins, jardins verticais, playgrounds, quartos de criança,<br className="hide" /> espaço pet, estúdios, decks de lazer, entre outros.
                            </p>
                        </div>
                    </div>
                    <div className="gramas-decorativas-info">
                        <div className="container">
                            <div className="slides-info decorativa-info">
                                <h3>Altura:</h3>
                                <div className="list">
                                    <div>
                                        <img src={graminha} alt="Graminha"/>
                                        <span>12mm</span>
                                    </div>
                                </div>
                            </div>
                            <div className="slides-info2 decorativa-info2">
                                <h4>Qualidade comprovada pelo laboratório de IPT</h4>
                                <p>(Instituto de pesquisas tecnológicas da cidade universitária de Sāo Paulo)</p>
                            </div>
                            <div className="slides-content">
                                <div>
                                    <img src={decorativaItem} alt="Decorativa"/>
                                    <h3>Produtos</h3>
                                    <span>Fibriladas</span>
                                    <ul>
                                        <li>Cor: <strong>Verde Oliva</strong></li>
                                        <li>Espessura: <strong>50 Micras</strong></li>
                                        <li>Gauge: <strong>7,5mm</strong></li>
                                        <li>PPD (10cm): <strong>19</strong></li>
                                        <li>Pontos (m2): <strong>40.000</strong></li>
                                    </ul>
                                    <a href="https://api.whatsapp.com/send?phone=5511969098399" target="_blank" rel="noreferrer"><FaWhatsapp size={20} color="#136019" /> Orçar pelo WhatsApp</a>
                                    <a href="#orcamento"><IoIosMail size={20} color="#136019" />Orçar por email</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grama-esportiva-gallery grama-esportiva-gallery2">
                        <div>
                            <img src={gramaDecorativa1} alt="Grama Decorativa 1"/>
                        </div>
                        <div>
                            <img src={gramaDecorativa2} alt="Grama Decorativa 2"/>
                        </div>
                        <div>
                            <img src={gramaDecorativa3} alt="Grama Decorativa 3"/>
                        </div>
                        <div>
                            <img src={gramaDecorativa4} alt="Grama Decorativa 4"/>
                        </div>
                        <div>
                            <img src={gramaDecorativa5} alt="Grama Decorativa 5"/>
                        </div>
                        <div>
                            <img src={gramaDecorativa6} alt="Grama Decorativa 6"/>
                        </div>
                    </div>
                </section>
                <section id="projetos-especiais">
                    <div className="filter">
                        <h1>Projetos<br className="title-mobile" /> Especiais</h1>
                        <br className="br_mobile" /><br className="br_mobile" />
                        <p>
                            Também executamos demandas especiais. <br className="hide" />
                            Consulte-nos.
                        </p>
                    </div>
                </section>
            </main>
            <Footer />
        </>
       
    );
}

export default Home
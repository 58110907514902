import React from 'react';
import {FaWhatsapp} from 'react-icons/fa';
import {IoIosMail} from 'react-icons/io';

import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';

import slide1 from '../../assets/slide1.png';
import slide2 from '../../assets/slide2.png';
import slide3 from '../../assets/slide3.png';
import slide4 from '../../assets/slide4.png';

function Slide() {
  SwiperCore.use([Pagination, Autoplay]);

  return (
    <>
      <div className="slides-content">
        <div>
          <Swiper slidesPerView={1} pagination={{ clickable: true }} autoplay>
            <SwiperSlide className="slide">
              <img src={slide1} alt="Slide 1"/>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={slide2} alt="Slide 2"/>
            </SwiperSlide>
          </Swiper>
          <h3>Produtos</h3>
          <span>Monofilada</span>
          <ul>
              <li>Cor: <strong>Verde</strong></li>
              <li>Espessura: <strong>280 Micras</strong></li>
              <li>Gauge: <strong>16mm a 19mm</strong></li>
              <li>PPD (10cm): <strong>11 a 15</strong></li>
              <li>Pontos (m2): <strong>6.875 a 7.890</strong></li>
          </ul>
          <a href="https://api.whatsapp.com/send?phone=5511969098399" target="_blank" rel="noreferrer"><FaWhatsapp size={20} color="#136019" /> Orçar pelo WhatsApp</a>
          <a href="#orcamento"><IoIosMail size={20} color="#136019" />Orçar por email</a>
        </div>
        <div>
          <Swiper slidesPerView={1} pagination={{ clickable: true }} autoplay>
            <SwiperSlide className="slide">
              <img src={slide3} alt="Slide 3"/>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <img src={slide4} alt="Slide 4"/>
            </SwiperSlide>
          </Swiper>
          <h3>Produtos</h3>
          <span>Fibrilada</span>
          <ul>
              <li>Cor: <strong>Verde</strong></li>
              <li>Espessura: <strong>80 Micras</strong></li>
              <li>Gauge: <strong>16mm a 19mm</strong></li>
              <li>PPD (10cm): <strong>11 a 15</strong></li>
              <li>Pontos (m2): <strong>6.875 a 7.890</strong></li>
          </ul>
          <a href="https://api.whatsapp.com/send?phone=5511969098399" target="_blank" rel="noreferrer"><FaWhatsapp size={20} color="#136019" /> Orçar pelo WhatsApp</a>
          <a href="#orcamento"><IoIosMail size={20} color="#136019" />Orçar por email</a>
        </div>
      </div>
    </>
  );
}

export default Slide;



                            
                     


